<template>
  <div style="max-width: 1250px; margin: auto">
    <br />
    <Button label="Ajouter une date" icon="pi pi-plus" @click="openDialog()" />

    <FullCalendar :options="calendarOptions" :events="this.getEvents()" />
    <Dialog
      :visible.sync="popupdisplay"
      :contentStyle="{ overflow: 'visible' }"
    >
      <template  style="height: 50px!important;" #header>
        <h3>{{ title }}</h3>
      </template>
      <p style="text-align: left">
        <b>{{ event.username }}</b>
      </p>
      <Calendar
        :inline="true"
        v-model="event.daterange"
        selectionMode="range"
        dateFormat="dd.mm.yy"
        v-bind:disabled="!editable"
      />
      <br /><br />
      <p style="text-align: left;margin-top: 0px;">Commentaire</p>
      <Textarea
        v-model="event.description"
        :autoResize="true"
        style="width: 100%"
        v-bind:disabled="!editable"
      />
      <template #footer>
        <Button
          v-if="editable"
          label="Annuler"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDialog()"
        />
        <Button
          v-if="editable && modify"
          label="Supprimer"
          icon="pi pi-trash"
          class="p-button-text"
          @click="deleteagenda()"
        />
        <Button
          v-if="editable"
          label="Confirmer"
          icon="pi pi-check"
          autofocus
          @click="confirmDateRange()"
        />
      </template>
    </Dialog>

    <Toast />
  </div>
</template>

<script>
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";

import { store } from "../store";

var moment = require("moment");

export default {
  computed: {
    Agendas() {
      return store.state.agendas;
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        editable: true,
        locales: [frLocale],
        locale: "fr",
        eventClick: (e) => {
          this.openDialog();
          var event = this.getEvent(e.event.id);
          event.daterange = [];
          event.daterange.push(e.event.start);
          event.daterange.push(e.event.end);
          this.event = event;
          this.title = "Modifier une date";
          this.modify = true;

          var agenda;
          store.state.agendas.forEach((ag) => {
            if (ag.id === e.event.id) agenda = ag;
          });

          if (store.state.userProfile.id !== agenda.userid) {
            console.log;
            this.title = "Consulter une date";
            this.editable = false;
          }
        },
        dateClick: (e) => {
          this.openDialog();
          var event = this.getEvent(e.id);
          event.daterange = [];
          event.daterange.push(e.date);
          this.event = event;
          this.modify = false;
        },
      },
      popupdisplay: false,
      event: {},
      title: "Ajouter une date",
      editable: true,
      modify: false,
    };
  },
  created() {
    store.dispatch("bindAgendas");
    store.dispatch("bindUsers");
  },
  methods: {
    getEvents() {
      var events = [];
      var i;
      for (i = 0; i < store.state.agendas.length; i++) {
        var event = {};
        event.id = store.state.agendas[i].id;
        event.title =
          this.getUser(store.state.agendas[i].userid).prenom +
          " " +
          this.getUser(store.state.agendas[i].userid).nom;
        event.start = store.state.agendas[i].dates[0];
        event.end = store.state.agendas[i].dates[1];

        store.state.users.forEach((us) => {
          if (us.id === store.state.agendas[i].userid) {
            event.color = us.color;
            event.textColor = this.getTextColor(us.color);
          }
        });

        if (store.state.agendas[i].description !== "")
          event.title += " - " + store.state.agendas[i].description;

        events.push(event);
      }
      return events;
    },
    getTextColor(rgbaarg) {
      let rgba = rgbaarg.match(/\d+/g);
      if (rgba === null) return "black";
      if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
        return "black";
      } else {
        return "white";
      }
    },
    getEvent(eventid) {
      var event = {};
      var i;
      for (i = 0; i < store.state.agendas.length; i++) {
        if (store.state.agendas[i].id === eventid) {
          event.id = store.state.agendas[i].id;
          event.username =
            this.getUser(store.state.agendas[i].userid).prenom +
            " " +
            this.getUser(store.state.agendas[i].userid).nom;
          event.description = store.state.agendas[i].description;
        }
      }
      return event;
    },
    openDialog() {
      this.popupdisplay = true;
      this.event = {};
      this.title = "Ajouter une date";
      this.editable = true;
      this.modify = false;
    },
    closeDialog() {
      this.popupdisplay = false;
      this.event = {};
    },
    deleteagenda() {
      store.dispatch("deleteAgenda",this.event.id);
      this.closeDialog();
      this.$toast.add({
        severity: "success",
        summary: "Date supprimée",
        detail: "Base bien mise à jour",
        life: 3000,
      });
    },
    confirmDateRange() {
      var datesarray = [];
      this.event.daterange.forEach((element) => {
        datesarray.push(moment(element).format("YYYY-MM-DD"));
      });

      if (this.event.description === undefined) this.event.description = "";

      var agenda = {
        description: this.event.description,
        dates: datesarray,
        userid: store.state.userProfile.id,
        timestamp: moment().format("DD/MM/YYYY hh:mm:ss"),
      };
      if (this.event.id === undefined) store.dispatch("addAgenda", agenda);
      else {
        agenda.id = this.event.id;
        store.dispatch("updateAgenda", agenda);
      }

      this.closeDialog();

      this.$toast.add({
        severity: "success",
        summary: "Ajout bien pris en compte",
        detail: "Base bien mise à jour",
        life: 3000,
      });
    },
    getUser(id) {
      var user = {
        prenom: "inconnu",
        nom: "",
      };
      store.state.users.forEach((element) => {
        if (element.id === id) user = element;
      });
      return user;
    },
  },
};
</script>

<style>



</style>